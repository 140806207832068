<template>
  <v-container fluid>
    <v-form ref="form">
      <v-sheet rounded class="pa-4" id="form-driver">
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="2">
            <label class="body-2 ma-0" for="driver">
              {{$_strings.driver.DRIVER}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :rules="rules.name"
              v-model="form.name"
              :disabled="isPageDetail"
              id="driver"
              class="body-2"
              required
              dense
              clearable
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="2">
            <label class="body-2 ma-0" for="email">
              {{$_strings.driver.EMAIL}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :rules="rules.email"
              v-model="form.email"
              :disabled="!!driverId || isPageDetail"
              id="email"
              required
              dense
              clearable
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="2">
            <label class="body-2 ma-0" for="phoneNumber">
              {{$_strings.driver.PHONE_NUMBER}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <phone-number-format
              id="phoneNumber"
              outlined
              dense
              v-model="form.phoneNo"
              :disabled="isPageDetail"
              clearable
              :required="true"
            ></phone-number-format>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="2">
            <label class="body-2 ma-0" for="licenseNo">
              {{$_strings.driver.LICENSE_NO}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.driver.LICENSE_NO)]"
              v-model="form.licenseNo"
              v-mask="{'alias': 'custom-integer',rightAlign: false}"
              :disabled="isPageDetail"
              id="licenseNo"
              required
              dense
              clearable
              outlined>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0" cols="12" sm="2">
            <label class="body-2 ma-0" for="licenseExpDate">
              {{$_strings.driver.LICENSE_EXP_DATE}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </label>
          </v-col>
          <v-col cols="auto" class="pt-0">
            <v-menu
              ref="menuLicenseExpDate"
              v-model="menuLicenseExpDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  id="licenseExpDate"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.driver.LICENSE_EXP_DATE)]"
                  :value="form.licenseExp ? dateFormat(form.licenseExp) : null"
                  dense
                  outlined
                  placeholder="Tanggal"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :disabled="isPageDetail"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.licenseExp"
                :disabled="isPageDetail"
                @change="menuLicenseExpDate = false"
                no-title
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4" v-for="(itemUpload, indexItemUpload) in formUpload" :key="indexItemUpload">
            <h4>{{itemUpload.label}}<v-icon color="red" class="icon_important" v-if="itemUpload.required">mdi-asterisk</v-icon></h4>
            <v-card
              class="mx-auto"
              outlined
            >
              <v-card
                v-if="!form[itemUpload.vModel]"
                height="200"
                class="d-flex justify-center align-center"
                flat
              >
                <p class="font-italic red--text" v-if="itemUpload.isError">*{{itemUpload.label}} wajib di upload*</p>
                <p class="font-italic grey--text" v-else>*Belum upload foto*</p>
              </v-card>
              <v-img
                v-else
                :src="form[itemUpload.vModel]"
                contain
                aspect-ratio="1"
                height="200"
                lazy-src="../../assets/images/placeholder-image.png"
              >
              </v-img>
              <v-card-actions v-if="!isPageDetail" class="white">
                <v-btn
                  v-if="!form[itemUpload.vModel]"
                  :disabled="!form.email"
                  :loading="itemUpload.isLoading"
                  color="blue-grey"
                  small
                  block
                  class="pa-4 white--text"
                  @click="$refs['file'+indexItemUpload][0].$refs.input.click()"
                >
                  Pilih Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  :loading="itemUpload.isLoading"
                  color="red"
                  small
                  block
                  class="pa-4 white--text"
                  @click="deletePhoto(itemUpload)"
                >
                  Hapus Foto
                  <v-icon
                    right
                    dark
                  >
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-file-input
                class="d-none"
                v-model="itemUpload.encodedFile"
                @change="($event) => setFileInput($event, itemUpload)"
                :ref="'file'+indexItemUpload"
                type="file"
                accept="image/*"
                outlined
                dense
              >
              </v-file-input>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet rounded class="pa-4 mt-4" id="form-vaccine">
        <h3>{{$_strings.driver.VACCINE_INFO}}</h3>
        <p class="mt-5">{{$_strings.driver.HAVE_VACCINE}}</p>
        <v-checkbox
          :disabled="isPageDetail"
          label="Ya, Saya sudah vaksin"
          v-model="isVaccine"
          @change="onChangeIsVaccine"
        />
        <v-row>
          <v-col cols="12" v-for="(subVaccines, index) in form.vaccines" :key="index">
            <v-row>
              <v-col cols="3">
                <v-chip
                  style="width: 100px"
                  class="float-right mr-8"
                  :color="subVaccines.name && subVaccines.date ? 'primary' : 'blue-grey lighten-5'"
                  v-model="subVaccines.number"
                  :disabled="isPageDetail"
                  small
                  rounded
                >
                  <span class="ml-2">
                    {{$_strings.driver.VACCINE}} {{index+1}}
                    <v-icon v-if="subVaccines.name && subVaccines.date" small>mdi-check-circle</v-icon>
                  </span>
                </v-chip>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="4">
                <p class="mb-0">{{$_strings.driver.VACCINE_NAME}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon></p>
                <v-autocomplete
                  dense
                  outlined
                  :items="itemsVaccines"
                  :loading="isLoadingListVaccines"
                  item-value="name"
                  item-text="name"
                  v-model="subVaccines.name"
                  :disabled="isPageDetail"
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Nama Vaksin')]"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="auto">
                <p class="mb-0">{{$_strings.driver.VACCINE_DATE}}<v-icon color="red" class="icon_important">mdi-asterisk</v-icon></p>
                <v-menu
                  ref="menuVaccineDate"
                  :close-on-content-click="false"
                  v-model="menuVaccineDate[index].menuDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-text-field
                      dense
                      outlined
                      :value="subVaccines.date ? dateFormat(subVaccines.date) : null"
                      readonly
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Tanggal')]"
                      :disabled="isPageDetail"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="subVaccines.date"
                    :disabled="isPageDetail"
                    @change="menuVaccineDate[index].menuDate = false"
                    no-title
                    scrollable
                    cl
                    locale="id"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col v-if="!isPageDetail" cols="12" md="2" class="d-flex align-center">
                <div v-if=" index === form.vaccines.length - 1">
                  <v-btn @click="pushVaccineForm" color="primary" text small plain>
                    <v-icon>
                      mdi-plus-circle
                    </v-icon>
                    <span class="addVaksin">{{$_strings.driver.ADD_VACCINE}}</span>
                  </v-btn>
                </div>
              </v-col>
              <v-col v-if="!isPageDetail" cols="auto">
                <v-btn @click="deleteSubVaccines(index)" color="red lighten-2" text small plain>
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-checkbox
          label="Belum"
          v-model="isNoVaccine"
          @change="onChangeIsNoVaccine"
          :disabled="isPageDetail"
        />
      </v-sheet>
    </v-form>
    <v-row class="mt-5">
      <v-col>
        <v-btn v-if="!isPageDetail" elevation="2" color="primary" class="float-right " @click="submit">
          <b>{{ $_strings.common.SAVE }}</b>
        </v-btn>
        <v-btn elevation="2" class="mr-2 float-right" @click="$router.go(-1)">
          <b>{{ $_strings.common.BACK }}</b>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';

export default {
  name: 'driverCreateEdit',
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
    } else if (this.driverId) {
      this.fetchDriverDetail();
    }
  },
  beforeDestroy() {
    if (this.newDataUrl.length && !this.isSubmited) {
      Promise.all(this.newDataUrl.map((payload) => this.$_services.uploads.deleteFile(payload)));
    }
    if (this.documentQueueDeletion.length && this.isSubmited) {
      Promise.all(this.documentQueueDeletion.map((payload) => this.$_services.uploads.deleteFile(payload)));
    }
  },
  data() {
    return {
      menuLicenseExpDate: false,
      menuVaccineDate: [],
      isLoadingListVaccines: false,
      isVaccine: false,
      isNoVaccine: true,
      itemsVaccines: [],
      formUpload: [
        {
          label: 'Foto Sopir',
          vModel: 'profileImageUrl',
          encodedFile: null,
          isLoading: false,
          isError: false,
          required: true,
        },
        {
          label: 'Foto SIM',
          vModel: 'licenseImageUrl',
          encodedFile: null,
          isLoading: false,
          isError: false,
          required: true,
        },
        {
          label: 'Foto KTP',
          vModel: 'idImageUrl',
          encodedFile: null,
          isLoading: false,
          isError: false,
          required: false,
        },
      ],
      form: {
        name: '',
        email: '',
        phoneNo: '',
        licenseNo: '',
        licenseExp: '',
        profileImageUrl: null,
        licenseImageUrl: null,
        idImageUrl: null,
        vaccines: [],
      },
      rules: {
        name: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED(this.$_strings.driver.DRIVER);
            }
            const pattern = /^[A-Za-z\s]+$/;
            return pattern.test(value) || 'Nama hanya diperbolehkan huruf!';
          },
        ],
        email: [
          (value) => {
            if (!value) {
              return this.$_strings.messages.validation.REQUIRED('Email');
            }
            return this.isEmailValid(value) || this.$_strings.messages.validation.EMAIL;
          },
        ],
      },
      newDataUrl: [],
      documentQueueDeletion: [],
      isSubmited: false,
    };
  },
  computed: {
    isPageDetail() {
      const pageDetail = this.$route.path.split('/')[3];
      return pageDetail === 'detail';
    },
    driverId() {
      return this.$route.params.driverId || null;
    },
    filePath() {
      const dataUser = this.$store.state.user.myUserInfo;
      const { companyId } = dataUser;
      const replaceEmail = this.form.email.replace('@', '_').replaceAll('.', '_');
      const filePath = `driver/${companyId}/${replaceEmail}/`;
      return filePath;
    },
  },
  methods: {
    dayjs,
    dateFormat,
    setData(data) {
      this.form = data;
      this.form.licenseExp = dayjs(data.licenseExp).format('YYYY-MM-DD');
      this.isVaccine = !!data.vaccines.length;
      this.isNoVaccine = !data.vaccines.length;
      if (this.form.vaccines.length) {
        this.form.vaccines.forEach((vaccine, index) => {
          this.form.vaccines[index].date = dayjs(this.form.vaccines[index].date).format('YYYY-MM-DD');
          this.itemsVaccines.push({
            name: vaccine.name,
          });
          this.menuVaccineDate.push({
            menuDate: false,
          });
        });
      }
      if (this.isPageDetail) {
        const { idImageUrl, licenseImageUrl, profileImageUrl } = this.form;
        const indexProfilImage = this.formUpload.findIndex((x) => x.vModel === 'profileImageUrl');
        if (!profileImageUrl) this.formUpload.splice(indexProfilImage, 1);
        const indexLicenseImageUrl = this.formUpload.findIndex((x) => x.vModel === 'licenseImageUrl');
        if (!licenseImageUrl) this.formUpload.splice(indexLicenseImageUrl, 1);
        const indexIdImageUrl = this.formUpload.findIndex((x) => x.vModel === 'idImageUrl');
        if (!idImageUrl) this.formUpload.splice(indexIdImageUrl, 1);
      }
      if (this.isVaccine && !this.isPageDetail) this.fetchVaccinesList();
    },
    async fetchDriverDetail() {
      try {
        this.$root.$loading.show();
        const res = await this.$_services.driver.driverDetail(this.driverId);
        this.setData(res.data);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchVaccinesList() {
      try {
        this.isLoadingListVaccines = true;
        const result = await this.$_services.driver.vaksin();
        this.itemsVaccines = result.data.contents;
      } finally {
        this.isLoadingListVaccines = false;
      }
    },
    deleteSubVaccines(index) {
      this.form.vaccines.splice(index, 1);
      this.menuVaccineDate.splice(index, 1);
      // re ordered vaccine number
      if (!this.form.vaccines.length) {
        this.isVaccine = false;
        this.isNoVaccine = true;
        return;
      }
      const newForm = [];
      this.form.vaccines.forEach((vaccine, indexVaccine) => {
        newForm.push({
          ...vaccine,
          number: indexVaccine + 1,
        });
      });
      this.form.vaccines = newForm;
    },
    pushVaccineForm() {
      if (!this.itemsVaccines.length) this.fetchVaccinesList();
      this.form.vaccines.push({
        number: this.form.vaccines.length + 1,
        name: '',
        date: '',
      });
      this.menuVaccineDate.push({
        menuDate: false,
      });
    },
    onChangeIsVaccine(value) {
      if (value) {
        this.isNoVaccine = false;
        return this.pushVaccineForm();
      }
      this.isNoVaccine = true;
      this.form.vaccines = [];
    },
    onChangeIsNoVaccine(value) {
      if (value) {
        this.form.vaccines = [];
        this.menuVaccineDate = [];
        this.isVaccine = false;
        return;
      }
      this.isVaccine = true;
      this.onChangeIsVaccine(true);
    },
    fileUploadValidation(event, fileSizeMin = 1024000) {
      if (!event) return;
      const { size, name } = event;
      const fileType = name.split('.').pop().toLowerCase();
      // 1024000 => 1MB
      const fileTypeSupport = ['jpg', 'jpeg', 'png'];
      if (!fileTypeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return false;
      }
      if (size > fileSizeMin) {
        this.$dialog.notify.error(`Ukuran file melebihi maksimum ${fileSizeMin / 1024000}MB.`);
        return false;
      }
      return true;
    },
    setFileInput(file, item) {
      const { name } = file;
      const valid = this.fileUploadValidation(file);
      if (!valid) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        item.isError = false;
        this.uploadPhoto(base64Image, name, item);
      };
      reader.readAsDataURL(file);
    },
    async deletePhoto(item) {
      const formDelete = {
        url: this.form[item.vModel],
      };
      let targetIndex = null;
      this.newDataUrl.forEach((data, index) => {
        if (JSON.stringify(data.url) === JSON.stringify(this.form[item.vModel])) {
          targetIndex = index;
        }
      });
      if (targetIndex !== null) {
        try {
          this.$set(item, 'isLoading', true);
          await this.$_services.uploads.deleteFile(formDelete);
          if (targetIndex !== null) {
            this.newDataUrl.splice(targetIndex, 1);
          }
          this.form[item.vModel] = null;
        } finally {
          this.$set(item, 'isLoading', false);
          this.$set(item, 'encodedFile', null);
        }
        return;
      }
      this.documentQueueDeletion.push({
        url: this.form[item.vModel],
      });
      this.form[item.vModel] = null;
      this.$set(item, 'encodedFile', null);
    },
    async uploadPhoto(base64Image, name, item) {
      item.isLoading = true;
      try {
        const result = await this.$_services.uploads.uploadImage(base64Image, name, this.filePath);
        this.$dialog.notify.success(`Berhasil mengupload "${item.label}"`);
        this.form[item.vModel] = result.data.url;
        this.newDataUrl.push({
          url: result.data.url,
        });
      } catch {
        this.$set(item, 'encodedFile', null);
      } finally {
        item.isLoading = false;
      }
    },
    validatePhoto() {
      let valid = true;
      this.formUpload.forEach((form) => {
        if (!this.form[form.vModel] && form.vModel !== 'idImageUrl') {
          form.isError = true;
          valid = false;
        }
      });
      return valid;
    },
    async submit() {
      const { companyId } = this.$route.params;
      const validPhoto = this.validatePhoto();
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      if (!validPhoto) return this.$dialog.notify.error('Foto sopir/sim wajib di upload');
      try {
        const form = {
          ...this.form,
          companyId,
        };
        this.$root.$loading.show();
        await this.$_services.driver.driverCreateEdit(form, this.driverId);
        this.isSubmited = true;
        if (this.driverId) {
          this.$dialog.notify.success(this.$_strings.driver.EDITED_DRIVER_CONFIRM_MSG);
        } else {
          this.$dialog.notify.success(this.$_strings.driver.SUCCESSFUL_ADD_DRIVER);
        }
        this.$router.push({
          name: 'driver-list',
          query: {
            ...this.$route.query,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
